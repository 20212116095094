import React from "react";
import PropTypes from "prop-types"
import Button from "../../components/basics/button/Button";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";

export default class FormForSettings extends React.Component {

    render() {
        const {handleSubmit, children, submitting, optionalButtons, icon, showButtons} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                {children}
                {
                    showButtons &&
                    <ButtonGroup fixed renderInPortal={false}>
                        {optionalButtons}
                        <Button isLoading={!!submitting} buttonColor={"success"} buttonStyle={"round"} icon={icon}/>
                    </ButtonGroup>
                }
            </form>
        );
    }

}

FormForSettings.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    icon: PropTypes.node,
    optionalButtons: PropTypes.array,
    showButtons: PropTypes.bool,
    submitting: PropTypes.bool,
};

FormForSettings.defaultProps = {
    icon: <i className="fas fa-save"/>,
    showButtons: true
};
